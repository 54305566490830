const MuiSelect = (theme) => ({
  styleOverrides: {
    icon: {
      width: '4rem',
      height: '4rem',
      top: 'calc(25% - 0.5em)',
    },
    select: {
      [theme.breakpoints.down('lg')]: {
        fontSize: '1.6rem',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.9rem',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '1.3rem',
      },
      color: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      alignItems: 'center',
    },
    outline: {
      color: 'blue',
    },
  },
});

export default MuiSelect;
