const MuiFormHelperText = (theme) => ({
  styleOverrides: {
    root: {
      fontSize: theme.typography.helperText.fontSize,
      fontWeight: theme.typography.fontWeightRegular,

      [theme.breakpoints.down('md')]: {
        fontSize: '1.2rem',
      },
    },
  },
});

export default MuiFormHelperText;
