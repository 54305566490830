import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

/**
 * apiSlice that help us send request to backend api
 */

export const siteProSlice = createApi({
  reducerPath: 'fusionApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SITE_PRO_URL }),
  endpoints: (build) => ({
    /**
     * Get Request
     * initial fetch of the officine data concerned
     */
    getOfficineAbsorbant: build.query({
      query: (term) => ({
        url: `/officines?api_app_id=${process.env.REACT_APP_SITE_PRO_API_ID}&api_app_key=${process.env.REACT_APP_SITE_PRO_API_KEY}&query=${term}`,
      }),
    }),
    /**
     * Get Request
     * Fetch group using site pro API
     */
    getGroupement: build.query({
      query: (term) => ({
        url: `/groupements?api_app_id=${process.env.REACT_APP_SITE_PRO_API_ID}&api_app_key=${process.env.REACT_APP_SITE_PRO_API_KEY}&query=${term}`,
      }),
    }),
    /**
     * Get Request
     * Fetch wholesaler using site pro API
     */
    getWholesalers: build.query({
      query: (term) => ({
        url: `wholesaler/search?api_app_id=${process.env.REACT_APP_SITE_PRO_API_ID}&api_app_key=${process.env.REACT_APP_SITE_PRO_API_KEY}&query=${term}`,
      }),
    }),
  }),
});

export const {
  useGetOfficineAbsorbantQuery,
  useGetGroupementQuery,
  useGetWholesalersQuery,
} = siteProSlice;
