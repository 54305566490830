import { createTheme } from '@mui/material/styles';
import palette from './palette';
import rejectTreatmentPalette from './rejectTreatmentPalette';
import typography from './typography';
import { MuiButton, MuiButtonProps } from './muiButtonTheme';
import MuiToolbar from './muiToolbar';
import { MuiOutlinedInputProps, MuiOutlinedInput } from './muiOutlinedInput';
import { MuiFab, MuiFabProps } from './muiFab';
import { MuiInputLabel, MuiInputLabelProps } from './muiInputLabel';
import MuiFormHelperText from './muiFormHelperText';
import MuiSelect from './muiSelect';
import MuiMenuItem from './muiMenuItem';
import MuiAutocomplete from './muiAutocomplete';

let theme = createTheme({
  palette,
  rejectTreatmentPalette,
});

theme = createTheme(theme, {
  typography: typography(theme),
});

theme = createTheme(theme, {
  components: {
    MuiButton: MuiButtonProps,
    MuiFab: MuiFabProps,
    MuiInputLabel: MuiInputLabelProps,
    MuiOutlinedInput: MuiOutlinedInputProps,
  },
});

theme = createTheme(theme, {
  components: {
    MuiButton: MuiButton(theme),
    MuiAutocomplete,
    MuiFab,
    MuiToolbar,
    MuiInputLabel: MuiInputLabel(theme),
    MuiSelect: MuiSelect(theme),
    MuiMenuItem,
    MuiOutlinedInput: MuiOutlinedInput(theme),
    MuiFormHelperText: MuiFormHelperText(theme),
  },
});

export default theme;
