const palette = {
  primary: {
    main: '#0091ff',
  },
  secondary: {
    main: '#e00d80',
  },
  dark: {
    main: '#01438f',
  },
  label: {
    main: '#00000099',
  },
  navbar: {
    main: '#FFFFFF',
  },
};

export default palette;
