import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * BaseButton component
 * @param { string } btnVariant - variant prop of the Mui Button component. exp: contained,outlined
 * @param { string } color - exp: primary,secondary
 * @param { string } label - name of the button
 * @param { function } onClickHandler - function to handle the click
 * @param { boolean } isDisabled - boolean value for disable the button
 */

const BaseButton = ({
  color,
  btnVariant,
  label,
  onClickHandler,
  isDisabled,
}) => {
  return (
    <Button
      onClick={() => onClickHandler()}
      variant={btnVariant}
      color={color}
      disabled={isDisabled}
      sx={{ textTransform: 'none', width: '100%' }}
    >
      {label}
    </Button>
  );
};

export default BaseButton;

BaseButton.propTypes = {
  btnVariant: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func,
  isDisabled: PropTypes.bool,
};
