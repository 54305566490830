const typography = (theme) => ({
  h1: {
    fontWeight: 500,
    fontSize: '2.8rem',

    [theme.breakpoints.down('lg')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  h2: {
    fontSize: '2rem',
    fontWeight: 500,

    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  h3: {
    fontWeight: 400,
    fontSize: '1.9rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.6rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  h3_bold: {
    fontSize: '1.9rem',
    fontWeight: 700,

    [theme.breakpoints.down('lg')]: {
      fontSize: '1.6rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  p: {
    fontSize: '1.9rem',
    fontWeight: 200,

    [theme.breakpoints.down('lg')]: {
      fontSize: '1.6rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  subtitle1: {
    fontSize: '1.6rem',
    fontWeight: 400,

    [theme.breakpoints.down('lg')]: {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  button1: {
    fontWeight: 400,
  },
  labelText: {
    fontWeight: 400,
  },
  helperText: {
    fontSize: '1.4rem',
    fontWeight: 300,
  },
  button: {
    textTransform: 'none',
  },
});

export default typography;
