import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container, Grid, IconButton, Typography } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

/**
 * header wrapper Grid comp customize
 */
const HeaderWrapper = styled(Grid)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  height: '14vh',
}));

/**
 *  Back icon wrapper customize for changing color
 */
const ButtonIcon = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

/**
 *  make Back icon button responsive
 */
const CustomIcon = styled(KeyboardBackspaceIcon)(({ theme }) => ({
  fontSize: '3.5vh',

  [theme.breakpoints.down('sm')]: {
    fontSize: '3vh',
  },
}));

/***
 * @param {string} title - Title printed on the header part of the page
 * @param {boolean} goPrevious - if true, the back button return to previous page
 * @param {string} tradeName - The trade name of the company
 */
export default function FormHeader({
  title = 'Formulaire',
  goPrevious = false,
  tradeName = '',
}) {
  const history = useHistory();
  const onClickBack = () => {
    if (goPrevious) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  return (
    <HeaderWrapper component="header" container role="banner">
      <Container maxWidth="lg">
        <Grid
          container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Grid item xs={2}>
            <ButtonIcon
              role="button"
              onClick={onClickBack}
              aria-label="retour à l'accueil"
            >
              <CustomIcon />
            </ButtonIcon>
          </Grid>
          <Grid item xs={8}>
            <Typography
              component="h1"
              variant="h1"
              sx={{ textAlign: 'center' }}
            >
              {title}
              <br />
              {tradeName}
            </Typography>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </Container>
    </HeaderWrapper>
  );
}
