export const MuiFab = {
  styleOverrides: {
    root: {
      backgroundColor: 'transparent',
      width: '100%',
      height: '100%',
      borderRadius: '5px',
    },
  },
};

export const MuiFabProps = {
  defaultProps: {
    disableRipple: true,
    disableFocusRipple: true,
  },
};
