import * as React from 'react';
import logo from '../../assets/logo.svg';
import { BaseButton } from '../buttons';
import { Grid } from '@mui/material';

const Header = ({ hideButton = false, setOpenModal = () => {} }) => {
  return (
    <header role="banner">
      <Grid container className="home-header">
        <Grid item sm={3} xs={5} className="d-flex">
          <img src={logo} alt="logo de biogaran" className="home-header__img" />
        </Grid>
        <Grid item sm={6} xs={2} />
        <Grid item sm={3} xs={5} className="d-flex justify-center">
          {!hideButton && (
            <BaseButton
              color="secondary"
              btnVariant="outlined"
              label="Je change d'officine"
              onClickHandler={setOpenModal}
            />
          )}
        </Grid>
      </Grid>
    </header>
  );
};

export default Header;
