import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import NotFound from './features/reject/components/NotFound/NotFound';

const Home = lazy(() => import('./features/home/Home'));
const HomePmp = lazy(() => import('./features/pmp/AppPmp'));
const HomeReject = lazy(() => import('./features/reject/AppReject'));

const App = () => (
  <Router>
    <Suspense fallback={<div>Chargement...</div>}>
      <CssBaseline />
      <div className="App">
        <Switch>
          <Route path="/" render={() => <Home />} exact />
          <Route path="/pmp" render={() => <HomePmp />} />
          <Route path="/rejects" render={() => <HomeReject />} />
          <Route path="*" component={NotFound} />
        </Switch>
      </div>
    </Suspense>
  </Router>
);

export default App;
