import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { authContext } from '../adalConfig';

/**
 * @description Regenerate the token when it expires
 */
export const refreshAndGetToken = () => {
  const token = authContext.getCachedToken(authContext.config.clientId);
  if (!token) {
    authContext.acquireToken(authContext.config.clientId, (error, tokenOut) => {
      if (error) {
        console.error(error);
        return null;
      }
      return tokenOut;
    });
  }
  return token;
};

export const getAuthHeader = () => {
  if (process.env.REACT_APP_DO_NOT_LOGIN === 'true') {
    return 'x-api-key';
  }
  return 'authorization';
};

export const getAuthHeaderValue = () => {
  if (process.env.REACT_APP_DO_NOT_LOGIN === 'true') {
    return process.env.REACT_APP_NEST_TOKEN;
  }
  return `Bearer ${refreshAndGetToken()}`;
};

/**
 * apiSlice that help us send request to backend api
 */

export const apiSlice = createApi({
  reducerPath: 'appApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (build) => ({
    /**
     * Get Request
     * initial fetch of the officine data concerned
     */
    getOfficineById: build.query({
      query: (id) => ({
        url: `officine/${id}`,
        headers: {
          [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
        },
      }),
    }),

    getOfficineRejectInformationsById: build.query({
      query: ([encodedId, rejectBilledEbxId]) => ({
        url: `rejectebx/${rejectBilledEbxId}?encodedId=${encodedId}`,
        headers: {
          [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
        },
      }),
    }),
    /**
     * Get Request
     *
     */
    getBilledOfficineRejectInformationsById: build.query({
      query: ([encodedId, rejectBilledEbxId]) => ({
        url: `rejectebx/${rejectBilledEbxId}?encodedId=${encodedId}`,
        headers: {
          [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
        },
      }),
    }),
    /**
     * Get Request
     *
     */
    getDeliveredOfficineRejectInformationsById: build.query({
      query: ([encodedId, rejectDeliveredEbxId]) => ({
        url: `rejectebx/${rejectDeliveredEbxId}?encodedId=${encodedId}`,
        headers: {
          [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
        },
      }),
    }),
    /**
     * Get Request
     * get an encryupted id from id
     */
    getEncryptedId: build.query({
      query: (id) => ({
        url: `officine/encrypt-id/${id}`,
        headers: {
          [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
        },
        responseHandler: (response) => response.text(),
      }),
    }),
    /**
     * Get Request
     * get all rejects
     */
    getRejects: build.query({
      query: () => ({
        url: `rejects`,
        headers: {
          [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
        },
      }),
    }),
    /**
     * Post Request
     * Règlements / Échéances
     */
    addPayment: build.mutation({
      query(body) {
        return {
          url: 'form',
          method: 'POST',
          body,
          headers: {
            [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
          },
        };
      },
    }),

    /**
     * Post Request
     * Données de l’officine
     */
    addOfficineData: build.mutation({
      query(body) {
        return {
          url: 'form',
          method: 'POST',
          body,
          headers: {
            [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
          },
        };
      },
    }),
    addFusionData: build.mutation({
      query(body) {
        return {
          url: 'form',
          method: 'POST',
          body,
          headers: {
            [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
          },
        };
      },
    }),
    addCloseData: build.mutation({
      query(body) {
        return {
          url: 'form',
          method: 'POST',
          body,
          headers: {
            [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
          },
        };
      },
    }),
    addContactData: build.mutation({
      query(body) {
        return {
          url: 'form',
          method: 'POST',
          body,
          headers: {
            [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
          },
        };
      },
    }),
    changeOwnerData: build.mutation({
      query(body) {
        return {
          url: 'form',
          method: 'POST',
          body,
          headers: {
            [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
          },
        };
      },
    }),
    lockReject: build.mutation({
      query(id) {
        return {
          url: `rejects/lock/${id}`,
          method: 'POST',
          headers: {
            [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
          },
        };
      },
    }),
    unlockReject: build.mutation({
      query(id) {
        return {
          url: `rejects/unlock/${id}`,
          method: 'POST',
          headers: {
            [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
          },
        };
      },
    }),
    getLockRejectsStatus: build.query({
      // eslint-disable-next-line no-unused-vars
      query: (_) => ({
        url: 'rejects/status/',
        method: 'GET',
        headers: {
          [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
        },
      }),
    }),
    getLockRejectStatus: build.query({
      query: (id) => ({
        url: `rejects/status/${id}`,
        method: 'GET',
        headers: {
          [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
        },
      }),
    }),
    correctRejectData: build.mutation({
      query: (body) => ({
        url: `rejects/treat`,
        method: 'POST',
        body,
        headers: {
          [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
        },
      }),
    }),
    deleteRejectData: build.mutation({
      query: (id) => ({
        url: `/rejects/${id}`,
        method: 'DELETE',
        headers: {
          [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
        },
      }),
    }),
    /**
     * Get Request
     * Get current user
     */
    getCurrentUser: build.query({
      query: () => ({
        url: 'users/current',
        method: 'GET',
        headers: {
          [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
        },
      }),
    }),
    getIfUserHasWritePermissions: build.query({
      query: (id) => ({
        url: `/rejects/permissions/edit/${id}`,
        method: 'GET',
        headers: {
          [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
        },
      }),
    }),
    /**
     * Get Request
     * get all reject actions
     */
    getRejectActions: build.query({
      query: () => ({
        url: `rejectActions`,
        headers: {
          [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
        },
      }),
    }),
    saveRejectAction: build.mutation({
      query: (body) => ({
        url: 'rejectActions',
        method: 'PUT',
        body,
        headers: {
          [`${getAuthHeader()}`]: `${getAuthHeaderValue()}`,
        },
      }),
    }),
  }),
});

export const {
  useAddPaymentMutation,
  useAddOfficineDataMutation,
  useAddCloseDataMutation,
  useAddFusionDataMutation,
  useAddContactDataMutation,
  useChangeOwnerDataMutation,
  useGetOfficineByIdQuery,
  useGetEncryptedIdQuery,
  useGetRejectsQuery,
  useGetLockRejectsStatusQuery,
  useGetLockRejectStatusQuery,
  useLazyGetLockRejectStatusQuery,
  useLazyGetOfficineRejectInformationsByIdQuery,
  useLazyGetBilledOfficineRejectInformationsByIdQuery,
  useLazyGetDeliveredOfficineRejectInformationsByIdQuery,
  useLockRejectMutation,
  useUnlockRejectMutation,
  useCorrectRejectDataMutation,
  useDeleteRejectDataMutation,
  useGetCurrentUserQuery,
  useLazyGetIfUserHasWritePermissionsQuery,
  useSaveRejectActionMutation,
  useGetRejectActionsQuery,
} = apiSlice;
