const rejectTreatmentPalette = {
  primary: {
    main: '#EAF4FD',
    shade: 'rgba(234, 244, 253, 0.4)',
  },
  secondary: {
    main: '#0091FF',
  },
  text: {
    primary: '#252525',
    secondary: '#656565',
    white: '#FFFFFF',
  },
  rejectCategory: {
    primaryCustodian: '#DF6309',
    secondaryCustodian: '#E00D80',
    wholesaler: '#9747FF',
  },
};
export default rejectTreatmentPalette;
