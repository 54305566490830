import React from 'react';
import PropTypes from 'prop-types';
import { Box, Fab, Typography } from '@mui/material';

/**
 * FloatingButton Button component which includes icon and label text
 * @param { string } ariaLabel - for accessibility
 * @param {function} handleClick - function to be called on click
 * @param {string} label - label text
 * @param {Component} icon - icon name
 * @param {Component} icon2 - icon2 name -- in case two icons are needed for one card
 */

const FloatingButton = ({ ariaLabel, handleClick, label, icon, icon2 }) => {
  return (
    <Box onClick={() => handleClick()}>
      <Fab
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '2rem',
          justifyContent: 'center',
          minHeight: ['15rem', '17rem', '20rem'],
          backgroundColor: '#FFFFFF'
        }}
        aria-label={ariaLabel}
      >
        <Box sx={{ display: 'flex' }}>
          {icon}
          {icon2}
        </Box>
        <Typography sx={{ marginTop: '1rem' }} variant="h3" color={'primary'}>
          {label}
        </Typography>
      </Fab>
    </Box>
  );
};

FloatingButton.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  title: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.element,
};

export default FloatingButton;
