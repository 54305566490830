import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.scss';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { runWithAdal } from 'react-adal';
import theme from './theme/theme';
import App from './App';
import store from './app/store';
import { authContext } from './adalConfig';

const DO_NOT_LOGIN = process.env.REACT_APP_DO_NOT_LOGIN === 'true';

runWithAdal(
  authContext,
  () => {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Provider>
      </React.StrictMode>,
      document.getElementById('root')
    );
  },
  DO_NOT_LOGIN
);
