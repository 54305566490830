export const MuiButton = (theme) => ({
  styleOverrides: {
    // Name of the slot
    root: {
      // Some CSS
      [theme.breakpoints.down('lg')]: {
        fontSize: '1.5rem',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.8rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.3rem',
      },
      borderRadius: '3rem',
    },
    outlinedPrimary: {
      '&:hover,&:active': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
    },
    outlinedSecondary: {
      '&:hover,&:active': {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
      },
    },
  },
});

export const MuiButtonProps = {
  defaultProps: {
    disableRipple: true,
    disableFocusRipple: true,
  },
};
