export const MuiInputLabel = (theme) => ({
  styleOverrides: {
    root: {
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.label.main,
      left: '-1rem',
      top: '-1.3rem',

      [theme.breakpoints.down('md')]: {
        fontSize: '1.6rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '1.9rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.4rem',
      },
    },
  },
});

export const MuiInputLabelProps = {
  defaultProps: {
    shrink: true,
  },
};
