// Display the view Not Found when the route doesn't match any route of react-router
import { Box, Container, Typography } from '@mui/material';
import { Header } from '../../../../components/layouts';
import error from '../../../../assets/404-error.svg';

const NotFound = () => (
  <Container>
    <Header hideButton />
    <Box
      sx={{
        padding: ['10vh 3vh', '10vh', '10vh 20vh'],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <img
        src={error}
        alt="logo de biogaran"
        style={{ width: '50%', height: 'auto' }}
      />
      <Box sx={{ marginTop: '10%' }}>
        <Typography variant="h1" component="h1" sx={{ textAlign: 'center' }}>
          OOPS ! <br />
          Vous devez passer par votre application NEO pour accéder aux
          formulaires
        </Typography>
      </Box>
    </Box>
  </Container>
);
export default NotFound;
