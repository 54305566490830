import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from '../features/apiSlice';
import { siteProSlice } from '../features/siteProSlice';

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [siteProSlice.reducerPath]: siteProSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(apiSlice.middleware)
      .concat(siteProSlice.middleware),
});

export default store;
