export const MuiOutlinedInput = (theme) => ({
  styleOverrides: {
    root: {
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.p.fontSize,

      [theme.breakpoints.down('md')]: {
        fontSize: '1.6rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.4rem',
      },
    },
    outlined: {
      border: 'none',
    },
  },
});

export const MuiOutlinedInputProps = {
  defaultProps: {
    notched: false,
  },
};
