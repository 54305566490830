import { adalFetch, AuthenticationContext, withAdalLogin } from 'react-adal';

export const adalConfig = {
  tenant: process.env.REACT_APP_AD_TENANT, // tenant is the tenant ID of your Azure AD tenant
  clientId: process.env.REACT_APP_AD_CLIENT_ID, // id of the app in Azure AD
  endpoints: {
    api: process.env.REACT_APP_AD_ENDPOINT,
  },
  redirectUri: process.env.REACT_APP_AD_REDIRECT_URI, // url de redirection
  postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT, // url appelée lors de la déconnexion
  cacheLocation: 'localStorage',
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(
  authContext,
  adalConfig.endpoints.api
);
